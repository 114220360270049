<template>
  <div class="v_logged_in" style="padding-top: 60px; text-align: center; height: 1000px;">
    <h1>Welcome!</h1>
  </div>
</template>

<script>

  export default {
    name: "src-pages-body-main-v_logged_in",
    data() {
      return {
        email: "",
        password: "",
        selectedNav: 'headNav',
        loadingAnimation: true
      }
    },
    methods: {

    }
  }
</script>

<style>

</style>
